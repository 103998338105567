import { validate_id  } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    name: 'FacialBiometricsVerification',
    mixins: [MAIN_CONTROLLER],

    data: () => ({
        id_number: '',
        photo_taken: false,
        photo_src: '',
        video_stream: null,
        is_mobile_view: false
    }),

    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },

    methods: {
      initialize_camera() {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            const video = this.$refs.video;
            if ('srcObject' in video) {
              video.srcObject = stream;
            } else {
              video.src = window.URL.createObjectURL(stream);
            }
            video.play();
            this.video_stream = stream;
            this.toggle_photo_sections();
          })
          .catch((error) => {
            console.error('Error accessing camera:', error);
          });
      },
      take_photo() {
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo_taken = true;
        this.photo_src = canvas.toDataURL();
        if (this.is_mobile_view) {
          $("#take_photo").hide();
          $("#submit_photo").show();
          $(".retake-photo").removeClass("d-none");
        }
      },
      showPopup() {
        $('#show_instruction').modal('show');
      },
      retake_photo() {
        this.photo_taken = false;
        this.photo_src = '';
        this.showPopup(); // Trigger the modal when retaking the photo
  
        if (this.is_mobile_view) {
          $("#take_photo").show();
          $("#submit_photo").hide();
        }
      },
      submit_photo() {
        if (!this.id_number.trim()) {
          $('.id-message').removeClass('d-none');
          setTimeout(() => {
            $('.id-message').addClass('d-none');
          }, 3000);
          return false;
        }

        if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
            $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
        }
        this.safps_verification();
      },
      safps_verification() {
        const TOKEN = localStorage.getItem('token');
        const SAFPS_URL = `${process.env.API_BASE_URL}/verify_api/personnel_verification/facial_biometrics_verification/`;
        const DATA = {
          id_number: this.id_number,
          biometric: this.photo_src,
          workflow_id: this.workflow_id
        };
        $.ajax({
          url: SAFPS_URL,
          method: 'POST',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          data: JSON.stringify(DATA),
          beforeSend: () => {
            Swal.fire({
              text: 'Loading ...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            this.fetch_current_service();
            var status_message = response.status;

            if (status_message == "success") {
              this.fetch_current_service();
            } else if (status_message == "Results not found please contact your service provider") {
              Swal.fire({
                icon: 'error',
                text: "Our service is currently unavailable. Please contact your service provider and try again later",
              })
            }
          },
          error: (error) => {
            var liveness_value = error.responseJSON.liveness;

            if (liveness_value == "User's image is too dark") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "User is wearing a hat") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "User is wearing glasses") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "User is not looking at the camera") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "User is using a digital device") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "There are two faces in the image") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "Face is not visible") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else if (liveness_value == "Face could not be processed") {
              Swal.fire({
                icon: 'warning',
                title: liveness_value,
              }).then(() => {
                location.reload();
              });
            } else {
              Swal.close();
              let error_message = 'Error retrieving data';

              if (error.responseJSON) {
                  error_message = error.responseJSON.id_number[0];
                  $('.error-message').text(error_message).removeClass('d-none');
                  setTimeout(() => {
                    $('.error-message').text('').addClass('d-none');
                  }, 3000);
              }
            }
          }
        });
      },
      stop_camera() {
        if (this.video_stream) {
          const tracks = this.video_stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.video_stream = null;
        }
      },
      toggle_photo_sections() {
        this.is_mobile_view = window.innerWidth <= 768;
        if (this.is_mobile_view) {
          // Mobile view
          $("#take_photo").show();
          $("#submit_photo").hide();
        } else {
          // Desktop view
          $("#take_photo").show();
          $("#submit_photo").show();
        }
      },
      handle_window_resize() {
        this.toggle_photo_sections();
      }
    },
    mounted() {
      this.initialize_camera();
      window.addEventListener('resize', this.handle_window_resize);
    },
    beforeDestroy() {
      this.stop_camera();
      window.removeEventListener('resize', this.handle_window_resize);
    },
    beforeRouteLeave(to, from, next) {
      this.stop_camera();
      next();
    },
  };