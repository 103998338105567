import { validate_id  } from "@/utils/validations";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";


export default {
    name: 'RefugeeVerificationLiveness',
    mixins: [MAIN_CONTROLLER],

    data: () => ({
        refugee_number: '',
        photo_taken: false,
        photo_src: '',
        video_stream: null,
        is_mobile_view: false
    }),

    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },

    methods: {
      close_modal() {
        $('#show_instruction').modal('hide');
      },
      initialize_camera() {
        navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
            const video = this.$refs.video;
            if ('srcObject' in video) {
              video.srcObject = stream;
            } else {
              video.src = window.URL.createObjectURL(stream);
            }
            video.play();
            this.video_stream = stream;
            this.toggle_photo_sections();
          })
          .catch((error) => {
            console.error('Error accessing camera:', error);
          });
      },
      take_photo() {
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const context = canvas.getContext('2d');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo_taken = true;
        this.photo_src = canvas.toDataURL();
        if (this.is_mobile_view) {
          $("#take_photo").hide();
          $("#submit_photo").show();
          $(".retake-photo").removeClass("d-none");
        }
      },
      retake_photo() {
        this.photo_taken = false;
        this.photo_src = '';
  
        if (this.is_mobile_view) {
          $("#take_photo").show();
          $("#submit_photo").hide();
        }
      },
      submit_photo() {
        if (!this.id_number.trim()) {
          $('.id-message').removeClass('d-none');
          setTimeout(() => {
            $('.id-message').addClass('d-none');
          }, 3000);
          return false;
        }

        if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
            $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
        }
      },
      stop_camera() {
        if (this.video_stream) {
          const tracks = this.video_stream.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
          this.video_stream = null;
        }
      },
      toggle_photo_sections() {
        this.is_mobile_view = window.innerWidth <= 768;
        if (this.is_mobile_view) {
          // Mobile view
          $("#take_photo").show();
          $("#submit_photo").hide();
        } else {
          // Desktop view
          $("#take_photo").show();
          $("#submit_photo").show();
        }
      },
      handle_window_resize() {
        this.toggle_photo_sections();
      }
    },
    mounted() {
      this.initialize_camera();
      setTimeout(() => {
        $('#show_instruction').modal('show');
      }, 100);
      window.addEventListener('resize', this.handle_window_resize);
    },
    beforeDestroy() {
      this.stop_camera();
      window.removeEventListener('resize', this.handle_window_resize);
    },
    beforeRouteLeave(to, from, next) {
      this.stop_camera();
      next();
    },
  };