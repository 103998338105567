<div class="my-5 p-4 mt-1">
    <div class="card p-4 shadow-sm w-80 mx-auto">
      <div class="card-header bg-white border-0 mb-5 fw-bold me-4 p-1">
        Set Rules For Your Services
      </div>

      <div v-if="services && services.length">
        <div
          v-for="service in services"
          :key="service.id"
          class="service-section mb-3"
        >
          <button
            class="btn btn-dark text-white text-xs d-flex justify-content-between align-items-center rounded-small w-100"
            @click="toggle_service_rules(service.id)"
          >
            {{ service.name }}
            <i :class="{'fa-caret-down': service.id !== active_service, 'fa-caret-up': service.id === active_service}" class="fa"></i>
          </button>


          <div v-if="active_service === service.id" class="mt-4">
            <p class="fw-bold text-xs">Rules for {{ service.name }}</p>
            <div class="form-group mb-3 text-xs">
              <label>How many attempts are you giving the user?</label>
              <input type="text" class="form-control form-control-sm w-25" value="5" readonly />
            </div>
            <div class="form-group mb-3 text-xs">
              <label>If death status is true should the user continue?</label>
              <input type="text" class="form-control form-control-sm w-25" value="Yes" readonly />
            </div>
            <div class="form-group mb-3 text-xs">
              <label>If ID number is blocked should the user continue?</label>
              <input type="text" class="form-control form-control-sm w-25" value="No" readonly />
            </div>

            <div class="d-flex justify-content-end mt-4">
              <button class="btn bg-green text-dark text-xxs btn-sm rounded-pill fw-bold shadow mb-2">Save Rules</button>
            </div>
          </div>
        </div>
      </div>

      

      <div v-else class="text-center mt-4">
        <p class="text-dark fw-bold text-xs">No Rules for Services Found</p>
      </div>
      <div class="d-flex justify-content-end mt-0">
        <button type="button" class="btn bg-green rounded-small text-xxs fw-bold shadow mt-4" @click="route_to_ordering">Next</button>
      </div>
    </div>
</div>


  
  
  
  
  