import NavBar from '@/components/NavBar/NavBar.vue';
import { DocusealBuilder } from '@docuseal/vue'

export default {
  name: 'TemplateConfiguration',

  components: {
    NavBar,
    DocusealBuilder
  },
  data: () => ({
    tags: [],
    token: '',
    role: 'CLIENT_ADMIN',
    template_name: '',
    template_id: '',
    temp_id: '',
    id: '',
    docuseal_template_id: '',
    loading: false
   }),
  methods: {
   get_all_tags(temp_id){
    const TOKEN = localStorage.getItem('token');
    const GET_ALL_TAGS_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/get_all_tags_api/${temp_id}/`;
      
      $.ajax({
        url: GET_ALL_TAGS_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
        },
        success: (response) => {
          if (response.token && response.tags && response.template_id) {
            this.token = response.token;
            this.template_id = response.template_id;
            this.id = response.id;
            this.template_name = response.template_name.toUpperCase();
            this.tags = JSON.stringify(response.tags, null, 2)  // Parse tags if needed, ensure it's an array/object
          } else {
            console.error("Incomplete response data");
          }
        },
        error: () => {
          this.loading = false; // Ensure loading is false even if there's an error
          let error_message = 'Error while getting tags';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
   },
  
   template_configuration_save(event) {
    const TOKEN = localStorage.getItem('token');
    const SAVE_TEMPLATE_CONFIGURATION_URL = `${process.env.API_BASE_URL}/verify_api/template_management_api/template_configuration_save_api/`;
    const saved_data = event.detail;
    const docuseal_builder = document.getElementById('docuseal-builder');
    this.docuseal_template_id = docuseal_builder.dataset.templateId;
    const payload = {
      template_id: this.docuseal_template_id,
      temp_id: this.temp_id,
      saved_data: saved_data
    };
    
    $.ajax({
      url: SAVE_TEMPLATE_CONFIGURATION_URL,
      method: 'POST',
      contentType: 'application/json',
      headers: {
        Authorization: `Token ${TOKEN}`
      },

      data: JSON.stringify(payload),
      success: () => {
      },
      error: () => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error while saving template configuration',
          confirmButtonText: 'OK'
        });
      }
  });

  },

  handleSave(event) {
    this.template_configuration_save(event);
  }

},
mounted() {
    const temp_id = this.temp_id || this.$route.params.template_id;
    this.temp_id = temp_id;
    this.get_all_tags(temp_id);
    // Event listener for auto-saving the template
    const docuseal_builder = document.getElementById('docuseal-builder');
    if (docuseal_builder) {
      docuseal_builder.addEventListener('save', this.handleSave);
    }
},

beforeUnmount() {
  const docuseal_builder = document.getElementById('docuseal-builder');
  if (docuseal_builder) {
    docuseal_builder.removeEventListener('save', this.handleSave);
  }
},
};
