import NavBar from '@/components/NavBar/NavBar.vue';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import Swal from 'sweetalert2';

export default {
  name: 'ViewAsset',
  components: {
    ClientSidebar,
    NavBar,
  },
  data() {
    return {
      asset_details: {
        id: '',
        asset_name: '',
        asset_description: '',
        uploaded_image: '',
        date_created: '',
        created_asset_id: null
      },
      asset_file: null,
      asset_data: '',
      qr_code_data: '',
      workflow_data: '',
      qr_link:''
    };
  },
  methods: {
    fetch_asset_details(asset_id) {

      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSET_DETAILS_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_details_api/${asset_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSET_DETAILS_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          this.asset_details = response.data;
          this.created_asset_id = response.asset_id;

          if (!this.asset_details.id) {
            this.asset_details.id = this.$route.params.id;
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.message : 'Failed to load asset details.',
            confirmButtonText: 'OK',
          });
        },
      });
    },
    handle_file_upload(event) {
      this.asset_file = event.target.files[0];
    },
    edit_asset() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const EDIT_ASSET_URL = `${BASE_URL}/verify_api/workflow_management_api/edit_asset_api/${this.asset_details.id}/`;

      const form_data = new FormData();
      form_data.append('asset_name', this.asset_details.asset_name);
      form_data.append('asset_description', this.asset_details.asset_description);
      form_data.append('asset_type', this.asset_details.asset_type__asset_type);
      if (this.asset_file) {
        form_data.append('uploaded_image', this.asset_file);
      }

      $.ajax({
        method: 'POST',
        url: EDIT_ASSET_URL,
        data: form_data,
        contentType: false,
        processData: false,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
            
            if (response.message==='Asset with the same name already exists'){
                Swal.fire({
                    icon: 'error',
                    text: 'Asset with the same name already exists',
                    button: 'Ok'
                  });
            }
            else{
            window.location.reload();
            }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.error : 'Failed to update asset.',
          });
        },
      });
    },

    view_asset(asset_id){
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_ASSET_WORKFLOW_URL = `${BASE_URL}/verify_api/workflow_management_api/get_asset_workflows_api/${asset_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_ASSET_WORKFLOW_URL,
        headers: {
          Authorization: `Token ${TOKEN}`,
        },
        success: (response) => {
          if (response.asset_workflows && response.asset_workflows.length > 0) {
            this.asset_data = response.asset_workflows[0].asset;
            this.qr_code_data = response.asset_workflows[0].qr_code;
            this.workflow_data = response.asset_workflows[0].workflow;
          }
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON ? error.responseJSON.error : 'Failed to update asset.',
          });
        },
      });
    },
    copy_qr_link() {
      navigator.clipboard.writeText(this.qr_link)
        .then(() => {
          this.success_message = 'Link copied to clipboard!';
          this.error_messages = '';
        })
        .catch(() => {
          this.error_messages = 'Failed to copy the link.';
          this.success_message = '';
        });
    }
  },
  mounted() {
    const asset_id = this.$route.params.id;
    this.fetch_asset_details(asset_id);
    this.view_asset(asset_id)
  },
};