import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import Swal from 'sweetalert2';
import $ from 'jquery';

export default {
  name: 'ViewPendingApplication',
  components: {
    AdminNavBar
  },
  data: () => ({
    profile_information: {},
    company_information: {},
    biometrics_verification: {},
    company_verification: null,
    user_id: null,
    checks: {
      facial_biometrics: true,
      id_match: true,
      company_verification: true
    },
    name: '',
    phone_number: '',
    loading: false
  }),
  methods: {
    fetch_pending_application_data(user_id) {
      const TOKEN = localStorage.getItem('token');
      const VIEW_PENDING_APPLICATION_API_URL = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/view_user_application_api/${user_id}`;

      $.ajax({
        url: VIEW_PENDING_APPLICATION_API_URL,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          this.loading = true;
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          this.profile_information = response.profile_information.length ? response.profile_information[0] : {};
          this.company_information = response.company_information.length ? response.company_information[0] : {};
          this.biometrics_verification = response.biometrics_verification,
          this.company_verification = response.company_verification,
          this.name = response.name,
          this.phone_number = response.phone_number
          Swal.close(); // Close the loading dialog on success
          this.loading = false; // Reset loading state
        },
        error: () => {
          Swal.close(); // Close the loading dialog on error
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error fetching pending application data.',
            confirmButtonText: 'OK'
          });
          this.loading = false; // Reset loading state
        }
      });
    },
    update_application_status(action) {
      const TOKEN = localStorage.getItem('token');
      const ACTION_APPLICATION_URL = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/action_application_api/${this.user_id}`;

      $.ajax({
        url: ACTION_APPLICATION_URL,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          action: action
        }),
        success: (response) => {
          if (response.status === 'Success'){
          Swal.fire({
            icon: 'success',
            text: response.message,
            confirmButtonText: 'OK'
          }).then(() => {
            this.$router.push({ name: 'client_applications' }); 
          });
        }else{
          Swal.fire({
            icon: 'error',
            text: response.message,
            confirmButtonText: 'OK'
          })
        }
        },
        error: (response) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: response.responseJSON.error || 'An error occurred while processing the application.',
            confirmButtonText: 'OK'
          });
        }
      });
    },
    approve_application() {{
      this.update_application_status('approve');
      }
    },
    decline_application() {{
      this.update_application_status('decline');
    }}
  },
  mounted() {
    const user_id = this.user_id || this.$route.params.user_id;
    this.user_id = user_id; // Store the user_id for future use
    this.fetch_pending_application_data(user_id);
  }
};