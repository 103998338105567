import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import NavBar from '@/components/NavBar/NavBar.vue';

export default {
  name: 'ServiceDashboard',
  components: {
    NavBar
  },
  computed: {
    project_id() {
      return this.$store.getters.get_project_id;
    }
  },
  data: () => ({ services: [], service_name: '', service_id: '', service_description: {}}),

  methods: {
    get_services() {
      const TOKEN = localStorage.getItem('token');
      const GET_SELECTED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_project_services/${this.project_id}`;
      
      $.ajax({
        url: GET_SELECTED_SERVICES_API,
        method: 'GET',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          this.services = response.all_services;
          this.$nextTick(() => {
            // ensures the DataTable initialization runs after the DOM updates and the table is rendered
            if ($.fn.dataTable.isDataTable('#service-table')) {
              // check if the DataTable is already initialized on the table element; if it is, destroy the existing DataTable instance to reinitialize it
              $('#service-table').DataTable().destroy();
            }
            // initialize the DataTable with the specified options
            $('#service-table').DataTable({
              responsive: true,
              paging: true,
              searching: true,
              ordering: true,
            });
          });
        },
        error: () => {
          Swal.close();
        }
      });
    },
    view_service(service_id) {
      const TOKEN = localStorage.getItem('token');
      const GET_SERVICE_DESCRIPTION = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/get_service_descprition/${service_id}`;

      $.ajax({
          url: GET_SERVICE_DESCRIPTION,
          method: 'GET',
          contentType: 'application/json',
          headers: {
          Authorization: `Token ${TOKEN}`
          },
          success: (response) => {
            Swal.close();
            const service = response.service_description.find(service => service.id === service_id);
            if (service) {
              this.service_description = service;

              try {
                const INPUT_STRING = this.service_description.service_input.replace(/'/g, '"');
                const OUTPUT_STRING = this.service_description.service_output.replace(/'/g, '"');
                this.service_description.service_input = JSON.parse(INPUT_STRING);
                this.service_description.service_output = JSON.parse(OUTPUT_STRING);
              } catch (error) {
                console.error('Error parsing service input/output:', error);
              }

              localStorage.setItem('service_description', JSON.stringify(this.service_description));
              this.$router.push({ name: 'view_service', params: { id: service_id }});
            } else {
              console.error('Service not found');
            }
          },
          error: () => {
          Swal.close();
          },
      })
    },
    remove_service(service_id) {
      Swal.fire({
        text: 'Are you sure you want to remove this service from your selected services?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const TOKEN = localStorage.getItem('token');
          const SAVE_REMOVED_SERVICES_API = `${process.env.API_BASE_URL}/verify_api/workflow_management_api/select_project_services_api/`;
          const IS_SELECTED = false;
    
          const FORM_DATA = {
            is_selected: IS_SELECTED,
            service_id: service_id,
            project_id: this.project_id
          };
    
          $.ajax({
            url: SAVE_REMOVED_SERVICES_API,
            method: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(FORM_DATA),
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: () => {
              Swal.close();
              this.removeServiceFromUI(service_id); // Remove service from the UI
              Swal.fire({
                text: 'Service removed successfully!',
                icon: 'success',
                confirmButtonText: 'OK'
              });
            },
            error: () => {
              Swal.close();
              Swal.fire({
                text: 'Failed to remove the service. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
            }
          });
        } else {
          window.location.reload();
        }
      });
    },
    // Method to remove service from the UI
    removeServiceFromUI(service_id) {
      // Implement logic to remove the service from the table or UI
      const serviceRow = document.querySelector(`#service-${service_id}`);
      if (serviceRow) {
        serviceRow.remove();
      }
    }
    ,
    view_client_services() {
      this.$router.push({ name: 'client_services' });
    }
  },
  mounted() {
    this.get_services();
  }
};