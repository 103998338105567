<div class="container-fluid bg-white vh-100 p-0">
	<div class="text-center mt-5 mb-2">
		<img src="@/assets/images/mobi_logo.png" width="200px" />
	</div>
	<div class="text-center mt-1">
		<nav aria-label="breadcrumb" class="w-50 mx-auto">
			<ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
				<li class="breadcrumb-item active cursive-text text-white" aria-current="page">
					Eligibility
				</li>
			</ol>
		</nav>
	</div>
	<div>
		<IdNumberInput @update-id-number="handle_id_input"/>
		<div class="text-center">
			<button 
			  type="submit" 
			  class="btn bg-green btn-sm mt-1 px-4 rounded-small shadow-sm fw-bold text-dark text-xs"
			>
			  Continue
			</button>   
		</div>
	</div>
</div>