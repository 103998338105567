<div class="container-fluid bg-white vh-100 p-0 d-flex flex-column align-items-center">
  <div class="text-center mt-5 mb-2">
    <img src="@/assets/images/mobi_logo.png" width="200px" />
  </div>

  <div class="text-center mt-1 w-100">
    <nav aria-label="breadcrumb" class="w-50 mx-auto">
      <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2">
        <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
          Anti Money Laundering
        </li>
      </ol>
    </nav>
  </div>

  <div class="d-flex flex-column align-items-center w-50 mt-2">
    <div class="form-group mb-4 w-75">
      <label for="firstName" class="fw-bold text-xs text-start d-block">First Name <span class="text-danger">*</span></label>
      <input
        v-model="first_name"
        type="text"
        class="form-control rounded-small shadow-sm w-100"
        id="firstName"
        placeholder="e.g., Joe"
        required
      />
    </div>

    <div class="form-group mb-4 w-75">
      <label for="lastName" class="fw-bold text-xs text-start d-block">Last Name <span class="text-danger">*</span></label>
      <input
        v-model="last_name"
        type="text"
        class="form-control rounded-small shadow-sm w-100"
        id="lastName"
        placeholder="e.g., Doe"
        required
      />
    </div>

    <div class="form-group mb-4 w-75">
      <label for="dateOfBirth" class="fw-bold text-xs text-start d-block">Date of Birth <span class="text-danger">*</span></label>
      <input
        v-model="date_of_birth"
        type="text"
        class="form-control rounded-small shadow-sm w-100"
        id="dateOfBirth"
        placeholder="YYYYMMDD"
        required
      />
      <small class="form-text text-danger d-none text-xs fw-bold invalid-dob"></small>
    </div>

    <div class="form-group mb-4 w-75">
      <label for="gender" class="fw-bold text-xs text-start d-block">Gender <span class="text-danger">*</span></label>
      <input
        v-model="gender"
        type="text"
        class="form-control rounded-small shadow-sm w-100"
        id="gender"
        placeholder="M or F"
        required
      />
    </div>

    <div class="form-group mb-4 w-75">
      <label for="countryCode" class="fw-bold text-xs text-start d-block">Country Code <span class="text-danger">*</span></label>
      <input
        v-model="country_code"
        type="text"
        class="form-control rounded-small shadow-sm w-100"
        id="countryCode"
        placeholder="e.g., ZAF"
        required
      />
    </div>

    <div class="text-center mb-3 mt-4">
      <button
        class="btn btn-sm bg-green text-dark rounded-small fw-bold text-xs shadow-sm"
        @click.prevent="anti_money_laundering_application_api"
      >
        Continue
      </button>
    </div>
  </div>
</div>




  