
    <h5 class="text-s text-center"><strong>Anti Money Laundering</strong></h5>
            <div class="row mt-2 mb-2">
                <div class="col-md-6 text-start">
                <label class="form-label text-xs">First Name <span class="text-danger fw-bold">*</span></label>
                <input v-model="first_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. Joe">
                </div>
                <div class="col-md-6 text-start">
                <label class="form-label text-xs">Last Name <span class="text-danger fw-bold">*</span></label>
                <input v-model="last_name" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. Doe">
                </div>
            </div>
            <div class="row mt-2 mb-2">
                <div class="col-md-6 text-start">
                    <label class="form-label text-xs">Date of Birth <span class="text-danger fw-bold">*</span></label>
                    <input v-model="date_of_birth" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="YYyymmdd">
                </div>
                <div class="col-md-6 text-start">
                    <label class="form-label text-xs">Gender <span class="text-danger fw-bold">*</span></label>
                    <input v-model="gender" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="M or F">
                </div>
            </div>
            <div class="row mt-2 mb-2">
                <div class=" text-start">
                    <label class="form-label text-xs">Country Code <span class="text-danger fw-bold">*</span></label>
                    <input v-model="country_code" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg. ZAF">
                </div>
            </div>
            <div class="col text-end">
                <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="anti_money_laundering_application_api">Submit</button>
            </div>
 
    <div class="modal" tabindex="-1" id="verification_results">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Verification Result</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div v-if="Object.keys(json_data).length > 0">
                    <h5 class="text-s text-start"><strong>Verification Result</strong></h5>
                    <pre>{{ formatted_data }}</pre>
                </div>
            </div>
            <div class="modal-footer">
                <router-link :to="{ name: 'services' }">
                    <button class="btn bg-green btn-sm text-xs text-end  rounded-small mt-3" data-bs-dismiss="modal">Back</button>
                </router-link>
            </div>
          </div>
        </div>
    </div>
