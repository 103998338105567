import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import Swal from 'sweetalert2';
import AdminSidebar from '@/components/AdminSidebar/AdminSidebar.vue';
import AdminNavBar from '@/components/AdminNavBar/AdminNavBar.vue';
import { validate_first_name, validate_last_name, validate_email, validate_phone_number, validate_name} from '@/utils/validations.js';

export default {
  name: 'SystemUsers',
  components: {
    AdminNavBar,
    AdminSidebar,
  },
  data: () => ({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    role: '',
    currentTab: localStorage.getItem('usersTab') || 'Admins',
    data: {
      ClientAdmins: [],
      Admins: [],
      Customers: [],
      Clients: []
    },
  }),
  methods: {
    switchTab(tab) {
      // Destroy the existing DataTable before switching tabs
      if ($.fn.dataTable.isDataTable('#usersTable')) {
        $('#usersTable').DataTable().destroy();
      }

      // Update the current tab and reinitialize the DataTable after the content is rendered
      this.currentTab = tab;
      localStorage.setItem('usersTab', tab); 
      this.$nextTick(() => {
        this.initDataTable();
      });
    },
    fetchUsers() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        this.showError('.error-message', 'Server configuration error. Please try again later.');
        return;
      }
      const GET_USERS_URL = `${BASE_URL}/verify_api/verify_admin_api/get_users_api/`;

      $.ajax({
        method: "GET",
        url: GET_USERS_URL,
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading users...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          this.data.ClientAdmins = response.ClientAdmin || [];
          this.data.Admins = response.SystemAdmin || [];
          this.data.Clients = response.Client || [];
          this.data.Customers = response.Customer || [];

          this.$nextTick(() => {
            this.initDataTable(); // Initialize DataTable after data is loaded
          });
          Swal.close();
        },
        error: () => {
          Swal.fire({
            icon: 'error',
            text: 'Failed to load users. Please try again.',
            confirmButtonText: 'OK'
          });
        }
      });
    },
    validate_inputs() {
      if (!validate_first_name(this.first_name)) return false;
      if (!validate_last_name(this.last_name)) return false;
      if (!validate_email(this.email)) return false;
      if (!validate_phone_number(this.phone_number)) return false;
      
      return true;
    },
    validate_phone_number() {
      this.phone_number = this.phone_number.replace(/\D/g, '');
    },
    validate_name(field_name) {
      this[field_name] = validate_name(this[field_name]);
    },
    add_user() {
      if (!this.validate_inputs()) {
        return;
      }
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        this.showError('.error-message', 'Server configuration error. Please try again later.');
        return;
      }
      const ADD_USER_URL = `${BASE_URL}/verify_api/system_management_api/add_user_api/`;
      const FORM_DATA = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone_number: this.phone_number,
        role: this.role
      };
      $.ajax({
        method: "POST",
        url: ADD_USER_URL,
        data: JSON.stringify(FORM_DATA),
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.fire({
            icon: 'success',
            text: response.status,
            confirmButtonText: 'OK'
          }).then(() => {
            window.location.reload();
          });
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON.error,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    initDataTable() {
      this.$nextTick(() => {
        if ($.fn.dataTable.isDataTable('#usersTable')) {
          $('#usersTable').DataTable().destroy();
        }
        $('#usersTable').DataTable({
          responsive: true,
        });
      });
    },
    view_admin(user_id) {
      this.$router.push({ name: 'view_admin', params: { user_id: user_id } });
    },
    view_client(user_id) {
      this.$router.push({ name: 'view_client', params: { user_id: user_id } });
    },
    view_customer(user_id) {
      this.$router.push({ name: 'view_customer', params: { user_id: user_id } });
    },
    view_client_admin(user_id) {
      this.$router.push({ name: 'view_client_admin', params: { user_id: user_id } });
    }
  },
  computed: {
    filteredData() {
      return this.data[this.currentTab];
    }
  },
  mounted() {
    this.fetchUsers();
  }
};
