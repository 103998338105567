import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
  name: 'AssignedWorkflows',
  mixins: [MAIN_CONTROLLER],

  data: () => ({
    loading: true,
    workflows: [],
    dataTable_initialized: false,  // To ensure DataTable is initialized only once
  }),
  mounted() {
    this.get_assigned_workflows();
  },
  methods: {
    get_assigned_workflows() {
      const TOKEN = localStorage.getItem('token');
      const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
      const BASE_URL = process.env.API_BASE_URL;
      const GET_WORKFLOWS_URL = `${BASE_URL}/verify_api/customer_management_api/get_project_workflow_api/?client_admin_id=${GET_CLIENT_ID}`;

      $.ajax({
        method: "GET",
        url: GET_WORKFLOWS_URL,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        success: (response) => {

          // Check if response.project_information exists and is an array
          if (Array.isArray(response.project_information)) {
            // Map over the project_information to extract relevant workflow details
            this.workflows = response.project_information.map(workflow => ({
              workflow_id: workflow.workflow_id,
              name: workflow.workflow_name,
              company_name: workflow.company_name,
              date_created: new Date().toLocaleDateString()
            }));
          }
          this.loading = false;
          this.$nextTick(() => {
            if (!this.dataTable_initialized) {
              $('#workflows-table').DataTable({
                paging: true,
                searching: true,
                info: true,
                lengthChange: true,
                pageLength: 5,
              });
              this.dataTable_initialized = true;  // Ensure DataTable is only initialized once
            }
          });
        },
        error: () => {
          this.loading = false;
        }
      });
    },
    format_date(date) {
      if (!date) return '';
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    onboard_workflow(workflow_id) {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      const CHECK_TERMS_URL = `${BASE_URL}/verify_api/customer_management_api/check_terms_and_conditions_confirmation/${workflow_id}`;

    $.ajax({
        method: "GET",
        url: CHECK_TERMS_URL,
        contentType: 'application/json',
        headers: {
            Authorization: `Token ${TOKEN}`
        },
        success: (response) => {
            this.$store.dispatch('update_workflow_id', workflow_id);
            if (response.status === 'Success' && response.is_confirmed === true) {
                this.fetch_current_service();
            } else if(response.status === 'error' && response.is_confirmed === false) {
                this.$router.push('customer_consent');
            }
        },
        error: () => {
          let error_message = 'Error while confirming terms and conditions';
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error_message,
            confirmButtonText: 'OK'
          });
        }
      });
  }
    }
}
