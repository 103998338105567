
        <h5 class="text-s text-center"><strong>Refugee Verification</strong></h5>
            <div class="row mt-2 mb-3">
                <div class="col text-start">
                <label class="form-label text-xs">Refugee Number <span class="text-danger fw-bold">*</span></label>
                <input v-model="refugee_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="eg., EUFSOM010112818">
                </div>
            </div>
            <div class="col text-end">
                <button type="submit" class="btn bg-green btn-sm text-xs shadow rounded-small mt-2 fw-bold" @click.prevent="refugee_verification_api">Submit</button>
            </div>

            <div class="modal" tabindex="-1" id="verification_results">
                <div class="modal-dialog modal-fullscreen">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Verification Result</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="Object.keys(json_data).length > 0">
                            <ul>
                                {{ json_data }}
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <router-link :to="{ name: 'services' }">
                            <button class="btn bg-green btn-sm text-xs text-end  rounded-small mt-3" data-bs-dismiss="modal">Back</button>
                        </router-link>
                    </div>
                  </div>
                </div>
              </div>
