<div class="container-fluid bg-white vh-100 p-0">
  <div class="text-center mt-5 mb-2">
    <img src="@/assets/images/mobi_logo.png" width="200px" />
  </div>

  <div class="text-center mt-1">
    <nav aria-label="breadcrumb" class="w-50 mx-auto">
      <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
        <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
          CIPC Verification
        </li>
      </ol>
    </nav>
  </div>

  <div class="d-flex ms-6 col-md-12 justify-content-center align-items-center custom-margin-left ">
    <div class="w-75 mx-auto">
      <div class="mb-3">
        <label for="company_name" class="form-label fw-bold text-xs">Company Name <span class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input 
            id="company_name"
            type="text"
            class="form-control  w-50 text-xs rounded-small p-3 shadow-sm"
            v-model="company_name" 
            placeholder="Enter Company Name"
          />
        </div>
        <small class="form-text text-danger d-none company-message text-xs fw-bold">
          Please fill out this field
        </small>
      </div>
      <div class="mb-3">
        <label for="reg_number" class="form-label fw-bold text-xs">Company Registration Number <span class="text-danger fw-bold">*</span></label>
        <div class="col">
          <input 
            id="reg_number"
            type="text"
            class="form-control w-50 text-xs rounded-small p-3 shadow-sm"
            v-model="reg_number"
            placeholder="Company Registration"
            @input="validate_reg_number"
          />
        </div>
        <small class="form-text text-danger d-none reg-message text-xs fw-bold">
          Please fill out this field
        </small>
        <small class="form-text text-danger d-none text-xs fw-bold error-message"></small>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button 
      type="submit" 
      class="btn bg-green btn-sm mt-1 px-4 rounded-small shadow-sm fw-bold text-dark text-xs"
      @click="save_cipc_info"
    >
      Continue
    </button>   
  </div>
</div>

