<div class="container-fluid bg-white vh-100 p-0">
  <div class="text-center mt-5 mb-2">
      <img src="@/assets/images/mobi_logo.png" width="200px" />
  </div>

  <div class="text-center mt-1">
      <nav aria-label="breadcrumb" class="w-50 mx-auto">
          <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
              <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
                  Facial Biometrics Verification
              </li>
          </ol>
      </nav>
  </div>

  <div class="d-flex justify-content-center align-items-center min-vh-50">
      <div class="w-50 p-3">
          <div class="text-center">
              <small class="text-danger mb-2 fw-bold text-xs">
                  <i class="fa fa-info-circle me-1"></i>
                  Please make sure you have your camera enabled!
              </small>
          </div>

          <div class="form-group mt-3">
              <label for="id_number" class="form-label fw-bold text-xxs ms-7">ID Number <span class="text-danger fw-bold">*</span></label>
              <input
                  id="id_number"
                  type="text"
                  class="form-control mx-auto w-75 text-xxs rounded-small p-2 shadow-sm"
                  v-model="id_number"
                  placeholder="Enter an SA ID Number"
              >
              <div class="d-flex flex-column w-75 mx-auto mt-1">
                  <small class="text-danger fw-bold text-xs d-none id-message">
                      Please fill out this field
                  </small>
                  <small class="text-danger fw-bold text-xs d-none invalid-message">
                      Please enter a valid ID number
                  </small>
              </div>
          </div>

          <div class="row mt-4">
              <div class="col-12 col-md-6 mb-3 text-center" id="take_photo">
                  <figure class="content position-relative d-flex justify-content-center align-items-center custom-height">
                      <video ref="video" id="video" class="invert mb-2 position-relative w-100 h-100" autoplay loop muted playsinline></video>
                      <div class="circle-overlay"></div>
                  </figure>
                  <button 
                      @click="take_photo" 
                      class="btn bg-green shadow-sm rounded text-xs w-50 fw-bold shadow"
                  >
                      {{ photo_taken ? 'Retake' : 'Take Photo' }}
                  </button>
              </div>

              <div class="col-12 col-md-6 mb-3 text-center" id="submit_photo">
                  <figure class="content position-relative d-flex justify-content-center align-items-center custom-height">
                      <input v-model="photo_src" name="src" type="text" class="d-none" />
                      <canvas ref="canvas" id="canvas" class="mb-2 position-relative w-100 h-100"></canvas>
                  </figure>
                  <button 
                      @click="retake_photo" 
                      type="button" 
                      class="btn bg-green rounded-pill text-xs mb-2 w-50 fw-bold shadow d-none retake-photo"
                  >
                      Retake Photo
                  </button>
                  <button 
                      @click="submit_photo" 
                      type="submit" 
                      class="btn bg-green shadow-sm rounded text-xs w-50 fw-bold shadow" 
                      :disabled="!photo_taken"
                  >
                      Submit
                  </button>
              </div>
          </div>
      </div>
  </div>
</div>


  
  
  
  
  