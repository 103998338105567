import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net';
import ClientSidebar from '@/components/ClientSidebar/ClientSidebar.vue';
import NavBar from '@/components/NavBar/NavBar.vue';
import { validate_email, validate_phone_number } from '@/utils/validations.js';
import { show_error } from '@/utils/validations.js';

export default {
  name: 'ClientAdminUsers',
  components: {
    ClientSidebar,
    NavBar
  },
  data: () => ({
    currentTab: 'Profile',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    date_joined: '',
    id_number: '',
    audit_trails: [],
    documents: [],
    current_sub_tab: 'CreditBureau', // Default to "Credit Bureau"
    active_credit_bureau_service: null, // Track active Credit Bureau service
    active_system_generated_service: null, // Track active System Generated service
    credit_bureau_services: {},
    system_generated_services: {},
    selected_document: null,
    selected_document_url: null 
  }),
  computed: {
    nm_results() {
      const get_aml = this.credit_bureau_services.find(
        service => service.service_name === "Anti Money Laundering" &&
        service.response &&
        service.response.Fields?.Applicants_IO?.Applicant?.[0]?.Attributes?.DSWatchlistVerification
      );
      return get_aml?.response?.Fields?.Applicants_IO?.Applicant?.[0]?.Attributes?.DSWatchlistVerification?.NmResults;
    },
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    },
    selectedServiceResult() {
      if (this.current_sub_tab === 'CreditBureau') {
        return this.credit_bureau_services.find(service => service.name === this.active_credit_bureau_service)?.result;
      } else if (this.current_sub_tab === 'SystemGenerated') {
        return this.system_generated_services.find(service => service.name === this.active_system_generated_service)?.questions;
      }
      return null;
    }
  },
  methods: {
    switchTab(tab) {
      // Update the current tab and reinitialize the DataTable after the content is rendered
      this.currentTab = tab;
      localStorage.setItem('Profile', tab);
    },

    switch_sub_tab(tab) {
      // Reset active services when switching between tabs
      this.current_sub_tab = tab;
      this.active_credit_bureau_service = null;
      this.active_system_generated_service = null;
    },

    toggle_service(serviceName) {
      // Toggle dropdown for Credit Bureau or System Generated services
      if (this.current_sub_tab === 'CreditBureau') {
        this.active_credit_bureau_service = this.active_credit_bureau_service === serviceName ? null : serviceName;
      } else if (this.current_sub_tab === 'SystemGenerated') {
        this.active_system_generated_service = this.active_system_generated_service === serviceName ? null : serviceName;
      }
    },

    validate_inputs() {
      if (!validate_email(this.email)) return false;
      if (!validate_phone_number(this.phone_number)) return false;
      return true;
    },

    is_object_or_array(value) {
      return typeof value === 'object' && value !== null;
    },
    format_label(key) {
      if (typeof key !== 'string') return '';
      return key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
    },

    get_customer_data() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        show_error('.error-message', 'Server configuration error. Please try again later.');
        return;
      }

      const customer_id = this.$route.params.id;
      const GET_CUSTOMER_DATA_URL = `${BASE_URL}/verify_api/workflow_management_api/view_customer_data/${customer_id}`;

      $.ajax({
        method: 'GET',
        url: GET_CUSTOMER_DATA_URL,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close();
          let data = response.data[0];
          let documents = response.documents
          this.first_name = data.first_name;
          this.last_name = data.last_name; // Corrected typo
          this.email = data.email;
          this.phone_number = data.phone_number;
          this.date_joined = data.date_joined;
          if (data.profile && data.profile.id_number) {
            this.id_number = data.profile.id_number; 
          } else {
            this.id_number = null;  
          }
          this.audit_trails = data.useraudittrail_set;
          this.documents = documents;
          this.isIframeVisible = new Array(this.documents.length).fill(false);
        },
        error: (error) => {
          Swal.fire({
            icon: 'error',
            text: error.responseJSON.error,
            confirmButtonText: 'OK'
          });
        }
      });
    },
    toggle_iframe(document) {
      // Toggle the iframe view for the selected document
      this.selected_document = this.selected_document && this.selected_document.id === document.id 
        ? null 
        : document;
    },
    show_document(url) {
      // Set the selected document URL to the one clicked
      this.selected_document_url = this.selected_document_url === url ? null : url;
    },
    get_customer_service_responses() {
      const TOKEN = localStorage.getItem('token');
      const BASE_URL = process.env.API_BASE_URL;
      if (!BASE_URL) {
        show_error('.error-message', 'Server configuration error. Please try again later.');
        return;
      }

      const customer_id = this.$route.params.id;
      const GET_SERVICE_RESPONSES = `${BASE_URL}/verify_api/workflow_management_api/get_customer_service_responses/${customer_id}/${this.workflow_id}/`;

      $.ajax({
        method: 'GET',
        url: GET_SERVICE_RESPONSES,
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
        },
        success: (response) => {
          Swal.close()
          this.system_generated_services = response.system_generated;
          this.credit_bureau_services = response.credit_bureau;
        }
      })

    }
  },
  mounted() {
    const saved_tab = localStorage.getItem('Profile');
    this.currentTab = saved_tab ? saved_tab : 'Profile';
    this.get_customer_data();
    this.get_customer_service_responses();
  }
};


