export default {
    name: 'ViewLogs',

    data: () => ({
      loading: false,
      logs: []
    }),

    computed: {
      client_id() {
        return this.$store.getters.get_client_id;
      }
    },

    methods: {
        view_all_logs(){
          const TOKEN = localStorage.getItem('token');
          const VIEW_ALL_LOGS_API = `${process.env.API_BASE_URL}/verify_api/verify_admin_api/view_api_logs/${this.client_id}`;
    
          $.ajax({
            url: VIEW_ALL_LOGS_API,
            method: 'GET',
            contentType: 'application/json',
            headers: {
              Authorization: `Token ${TOKEN}`
            },
            beforeSend: () => {
              Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                }
              });
            },
            success: (response) => {
              Swal.close();
              this.logs = response.data;
              this.$nextTick(() => {
              if ($.fn.dataTable.isDataTable('#logs_table')) {
                $('#logs_table').DataTable().destroy();
              }
              $('#alogs_table').DataTable({
                responsive: true
              });
             });
    
            },
            error: (error) => {
              Swal.close();
              let error_message = 'Failed to get selected services';
              if (error.responseJSON) {
                const ERROR_RESPONSE = error.responseJSON;
                if (ERROR_RESPONSE && ERROR_RESPONSE.error) {
                  error_message = ERROR_RESPONSE.error;
                }
              }
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error_message,
                confirmButtonText: 'OK'
              });
            }
          });
        },
    },
    
    mounted() {
        this.view_all_logs();
    }

}