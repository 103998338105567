export default {
  name: 'CompanyVerificationPdf',
  data: () => ({
    reg_number: '',
    error: '',
    pdf_url: '',
    showModal: false,
    json_data: {}
  }),
  
  computed: {
    reg1() {
      return this.reg_number.slice(0, 4);
    },
    reg2() {
      return this.reg_number.slice(5, 11).replace('/', '');
    },
    reg3() {
      return this.reg_number.slice(12);
    }
  },

  methods: {
    close_modal() {
      $('#pdf_verification_results').modal('hide');
    },
    validate_reg_number(event) {
      let input_value = event.target.value;
      let cursor_position = event.target.selectionStart;

      if (event.key === 'Backspace' && cursor_position === 0) {
          return;
      }

      let clean_input = input_value.replace(/\D/g, '');
      let unformatted_length = clean_input.length;

      let formatted_input;
      if (clean_input.length > 4 && clean_input.length <= 8) {
          formatted_input = clean_input.replace(/(\d{4})(\d{0,6})/, '$1/$2');
      } else if (clean_input.length > 8) {
          formatted_input = clean_input.replace(/(\d{4})(\d{0,6})(\d{0,2})/, '$1/$2/$3');
      } else {
          formatted_input = clean_input;
      }

      formatted_input = formatted_input.slice(0, 14);
      this.reg_number = formatted_input;

      this.$nextTick(() => {
          let new_cursor_position = cursor_position + (formatted_input.length - unformatted_length);
          event.target.selectionStart = new_cursor_position;
          event.target.selectionEnd = new_cursor_position;
      });
    },
    cipc_api() {
      // Check if the registration number is valid
      if (this.reg_number.length < 14) {
        $('.reg-message').text('Invalid registration number').removeClass('d-none');
        return;
      } else {
        $('.reg-message').addClass('d-none');
      }

      const TOKEN = localStorage.getItem('token');
      const CIPC_API = `${process.env.API_BASE_URL}/verify_api/compliance_verification/cipc_base64/`;

      this.error = '';

      $.ajax({
        url: CIPC_API,
        method: 'POST',
        contentType: 'application/json',
        headers: {
          Authorization: `Token ${TOKEN}`
        },
        data: JSON.stringify({
          reg1: this.reg1,
          reg2: this.reg2,
          reg3: this.reg3
        }),
        beforeSend: () => {
          Swal.fire({
            text: 'Loading ...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading()
          });
        },
        success: (response) => {
          Swal.close();
          let status = response.status;
          let message = response.error_message;

          if (status === 'success'){
            Swal.fire({
              icon: 'success',
              title: 'Successful',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton: false
            })
            let inner_result = response.result.result;
            inner_result = JSON.parse(inner_result);
            this.pdf_url = inner_result.url_link;
            $('#pdf_verification_results').modal('show');

          } else if (status === 'error'){
            try {
              let parsed_error = JSON.parse(message);
              message = parsed_error.NoResult?.NotFound || 'An error occurred';
            } catch (e) {
              message = 'An error occurred';
            }
            Swal.fire({
              icon: 'error',
              title: message,
              confirmButtonText: 'OK'
            });
          }
        },
        error: (error) => {
          Swal.close();
          let error_message = 'Something went wrong!';

          if (error.responseJSON && error.responseJSON.message) {
            error_message = error.responseJSON.message;
          }

          $('.error-message').text(error_message).removeClass('d-none');
          setTimeout(() => {
            $('.error-message').text('').addClass('d-none');
          }, 3000);
        }
      });
    }
  }
};


  