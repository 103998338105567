<div class="container-fluid bg-white vh-100 p-0 d-flex flex-column align-items-center">
    <div class="text-center mt-5 mb-2">
      <img src="@/assets/images/mobi_logo.png" width="200px" />
    </div>
  
    <div class="text-center mt-1 w-100">
      <nav aria-label="breadcrumb" class="w-50 mx-auto">
        <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2">
          <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
            Proof Of Income Online
          </li>
        </ol>
      </nav>
    </div>
  
    <div class="d-flex flex-column align-items-center w-50 mt-2">

      <UserInfoFields
        v-model:first_name="first_name"
        v-model:last_name="last_name"
        v-model:email_address="email_address"
        v-model:phone_number="phone_number"
      />
  
      <!-- Bank -->
      <div class="form-group mb-4 w-75">
        <label for="bank" class="fw-bold text-xs text-start d-block">Bank <span class="text-danger">*</span></label>
        <input
          v-model="bank"
          type="text"
          class="form-control rounded-small shadow-sm w-100"
          id="bank"
          placeholder="e.g., FXXXB"
          required
        />
      </div>
  
      <!-- Bank Account Number -->
      <div class="form-group mb-4 w-75">
        <label for="bank_account_number" class="fw-bold text-xs text-start d-block">Bank Account Number <span class="text-danger">*</span></label>
        <input
          v-model="bank_account_number"
          type="text"
          class="form-control rounded-small shadow-sm w-100"
          id="bank_account_number"
          placeholder="e.g., XXXXXXXXXXX"
          required
        />
      </div>

      <!-- Submit Button -->
      <div class="text-center mb-3 mt-4">
        <button
          class="btn btn-sm bg-green text-dark rounded-small fw-bold text-xs shadow-sm"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
  