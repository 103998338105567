import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    name: 'QuestionBasedAuthentication',
    mixins: [MAIN_CONTROLLER],

    data() {
      return {
        results: {
          question_data: [], // API question data will go here
        },
      };
    },
    mounted() {
      this.fetch_qba_question();
    },
    computed: {
      workflow_id() {
        return this.$store.getters.get_workflow_id;
      }
    },
    methods: {

      fetch_qba_question(){
        const TOKEN = localStorage.getItem('token');
        const GET_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/question_based_authentication/${this.workflow_id}`;
  
        $.ajax({
          url: GET_QUESTIONS_API,
          method: 'GET',
          contentType: 'application/json',
          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Getting questions...',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            if(response.status === "success") {
              this.results = response.data;
            }
            
            },
          error: () => {
            Swal.fire({
              text: 'An error occurred',
              icon: 'error'
              
            });
            Swal.close()
          }
        });
      },
      
      submit_answers(){
        const TOKEN = localStorage.getItem('token');
        const SUBMIT_QUESTIONS_API = `${process.env.API_BASE_URL}/verify_api/personnel_verification/submit_qba_answers/`;
        var selected_answers = [];

        // Collect selected answers from checkboxes
        $('.checkbox-options input:checked').each(function() {
            var question_id = $(this).data('question-id');
            var answer_id = $(this).data('answer-id');
            selected_answers.push({ question_id: question_id, answer_id: answer_id, workflow_id: this.workflow_id });
        });
    
        // Collect selected answers from radio buttons
        $('.options input:checked').each(function() {
            var question_id = $(this).data('question-id');
            var answer_id = $(this).data('answer-id');
            selected_answers.push({ question_id: question_id, answer_id: answer_id,workflow_id: this.workflow_id });
        });
    
        // Validate selected answers
        if (selected_answers.length < 5) {
            Swal.fire({
                icon: "error",
                title: 'Please select one option for each question.',
                confirmButtonText: "OK"
            });
            return; // Exit if validation fails
        }

        $.ajax({
          url: SUBMIT_QUESTIONS_API,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(selected_answers),

          headers: {
            Authorization: `Token ${TOKEN}`
          },
          beforeSend: () => {
            Swal.fire({
              text: 'Submitting answers..',
              allowEscapeKey: false,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });
          },
          success: (response) => {
            Swal.close();
            if(response.status == "success"){
              this.fetch_current_service();
            }else{
              this.$router.push('/thank_you_page');
            }
            },
          error: () => {
            Swal.fire({
              text: 'An error occurred',
              icon: 'error'
              
            });
            Swal.close()
          }
        });
      },
    },
  };
  