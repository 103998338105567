
export default {
    name: 'IdNumberInput',
    data() {
        return {
          id_number: '',
        };
    },
    watch: {
        // Watch the id_number property for changes
        id_number(new_value) {
        this.$emit("update-id-number", new_value); // Emit the new value to the parent component
        },
    },
}