import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    name: 'ProofOfIncomeManual',
    mixins: [MAIN_CONTROLLER],
    data: () => ({ 
        first_name: '',
        last_name: '',
        email_address:'',
        phone_number:'',
        error: '',
        json_data: {}
    })
}