<div>
	<div class="col-md-5 offset-md-5 text-right">
		<img src="@/assets/images/new_black_logo2.png" width="300px" class="mb-2 p-5">
	</div>
	<div class="text-center">
		
		<P class="fw-bold mt-5 mb-5 text-x text-center">Credit Report Base64</P>
	</div>
	
	<div class="form-group form-control-sm mb-2 p-5 text-xs">
		<input type="text"
		class="form-control rounded text-xs" 
		maxlength="13"
		minlength="13" 
		v-model="id_number"
		placeholder="ID Number"
		@input="allow_num"
		>
		<small class="text-danger p-2 text-xxs fw-bold d-none id-message">Please fill out this field</small>
		<small class="text-danger p-2 text-xxs fw-bold d-none invalid-message"> Please enter a valid ID number</small>
		<div class="error-message">{{ error }}</div>
    </div>
	
	<div class="d-flex justify-content-center mt-4">
		<button type="submit" class="btn btn-custom btn-sm mt-4 mb-4 shadow-sm rounded text-black fw-bold" @click="submit">Submit</button>
	</div>
</div>