<div class="container-fluid bg-white vh-100 p-0">
    <div class="text-center mt-5 mb-2">
        <img src="@/assets/images/mobi_logo.png" width="250px">
    </div>

    <div class="text-center mt-4">
        <nav aria-label="breadcrumb" class="w-50 mx-auto">
            <ol class="breadcrumb rounded-3 justify-content-center bg-dark text-white p-2 mb-4">
                <li class="breadcrumb-item active cursive-text text-white" aria-current="page">
                    Thank You!
                </li>
            </ol>
        </nav>

        <div class="mb-5 mt-5">
            <img src="@/assets/images/workflow_done.png" alt="workflow completed" width="10%" height="auto">
        </div>

        <small class="cursive-text text-secondary fw-bold d-block mb-2 mt-4">
            You have successfully completed the workflow.
        </small>
        <small class="cursive-text text-secondary fw-bold d-block mb-5">
            We will be in contact with you soon.
        </small>

        <div class="text-center mb-3 mt-3">
            <button class="btn bg-green text-dark rounded-3 fw-bold text-xs" @click="redirect_to_dashboard">OK</button>
        </div>
    </div>
</div>
