<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-md-4">
      <div class="card card-bg shadow rounded-3  border-0 p">
          <div class="card-body">
              <div class="d-flex justify-content-between px-md-1">
                  <div>
                  <h6 >Customers</h6>
                  <p class="mb-0 sub_heading text-xsm fw-bold">
                    {{ total_customer_count }}
                  </p>
                  </div>
                  <div class="align-self-center rounded-circle p-2 shadow bg-green">
                    <i class="fa fa-cogs fa-lg me-auto text-dark"></i>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>

  <div class="row">
      <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-3">
          <div class="card-header bg-white border-0">
            Customers in your workflow
          </div>
          <div class="card-body">
            <table id="customers_table" class="table table-clear">
              <thead class="bg-dark text-white">
                <tr>
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">NO.</th>
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Customer</th>
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Date Created</th>
                  <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(customer, index) in customers" :key="customer.id">
                  <td class="text-xxs text-start">{{ index + 1 }}</td>
                  <td class="text-xxs text-start">{{ customer.first_name }} {{ customer.last_name }}
                    <p class="text-xxs ">{{ customer.email }}</p>
                  </td>
                  <td class="text-xxs text-start">{{ format_date(customer.date_joined) }}</td>
                  <td>
                    <button class="btn bg-green btn-sm text-xxs fw-bold rounded-pill" @click="view_cutsomer_data(customer.id)" >View</button>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>
</div>