<div class="d-flex justify-content-center align-items-center min-vh-50">
	<div class="w-50 p-3">
		<div class="form-group mt-3">
		<label for="id_number" class="form-label fw-bold text-xxs ms-7">ID Number <span class="text-danger fw-bold">*</span></label>
		<input
			  id="id_number"
			  type="text"
			  class="form-control mx-auto w-75 text-xxs rounded-small p-2 shadow-sm"
			  v-model="id_number"
			  placeholder="Enter ID Number"
		  >
		  <div class="d-flex flex-column w-75 mx-auto mt-1">
			  <small class="text-danger fw-bold text-xs d-none id-message">
				  Please fill out this field
			  </small>
			  <small class="text-danger fw-bold text-xs d-none invalid-message">
				  Please enter a valid ID number
			  </small>
		  </div>
		</div>
	</div>
</div>