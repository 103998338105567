<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6 col-md-4 mb-2">
      <div class="card card-bg shadow rounded-3 mt-4">
        <div class="card-body flex-column align-items-start">
          <div class="d-flex align-items-center mb-2">
            <div class="icon me-4">
              <img src="@/assets/images/available-services.png" alt="Logo" class="icon"/>
            </div>
            <h5 class="card-title fw-bold mb-0">Available Services</h5>
          </div>
          <div class="text-end mt-3 w-100">
            <button class="btn bg-green rounded-pill fw-bold btn-sm" @click="view_client_services()">View</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-0">
    <div class="col-md-12">
      <div class="card rounded-3 p-0 mt-3">
        <div class="card-header bg-dark text-center p-2">
          <h6 class="card-heading fw-bold text-white text-s">Your Selected Services</h6>
        </div>
        <div class="card-body">
          <table id="service-table" class="table-responsive w-100">
            <thead class="bg-gray">
              <tr>
                <th class="text-center text-uppercase text-xs font-weight-bolder opacity-7">ID</th>
                <th class="text-uppercase text-xs font-weight-bolder opacity-7">Service Name</th>
                <th class="text-uppercase text-xs font-weight-bolder opacity-7">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(service, index) in services" :key="service.service_id" :id="`service-${service.service_id}`">
                <td class="text-center text-xs fw-bold">{{ index + 1 }}</td>
                <td class="text-xs fw-bold">{{ service.service_name }}</td>
                <td>
                  <button class="btn bg-green rounded-pill fw-bold btn-sm me-2 text-xs" @click="view_service(service.service_id)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>







  

