
<form  method="post" @submit.prevent="login">
    <div class="text-center">
        <p class="text-dark fw-bolder mb-3 text-x">Login</p>
    </div>

    <div class="form-group mb-3">
        <input 
            type="text" 
            class="form-control rounded text-xs" 
            placeholder="Enter Email"  
            v-model="username" 
            name="username"
            >
            <small class="text-danger p-2 text-xxs fw-bold d-none username-message">Please fill out this field</small>
    </div> 

    <div class="form-group mb-3">
        <input 
            type="password" 
            class="form-control rounded text-xs" 
            placeholder="Enter Password" 
            v-model="password" 
            name="password" 
            >
            <small class="text-danger p-2 text-xxs fw-bold d-none password-message">Please fill out this field</small>
    </div>

    <div>
      <GetQuestions :client_admin_id="client_admin_id" page_type="login"  @update-responses="store_responses" />
    </div>

    <small class="text-danger p-2 text-xxs fw-bold d-none login-message"></small>
    <div class="mb-3">
        <button type="submit" class="btn btn-lg bg-green rounded text-xs w-75 fw-bold mt-2" @click="route_to">Login</button>
    </div>

    <div class="mt-1 text-center">
        <span class="text-dark text-xs">Not Registered?</span>
        <a class="text-primary text-xs"><router-link to="/user_registration"> Register here</router-link></a>
    </div>
    <div class="text-center">
        <span class="text-dark text-xs">Forgot password?</span>
        <a :href="reset_url" class="text-mute text-xs"> Reset password</a>
    </div>
</form>