<div class="container-fluid mt-4">
            <div class="card rounded-3 border-0 shadow p-2">
                <div class="card-header bg-white border-0">
                    Create Question
                </div>
                <div class="card-body">
                    <form @submit.prevent="create_question">
                        <div class="row mt-2 mb-3">
                          <div class="col">
                            <label class="form-label fw-bold text-xs"><small>Question</small></label>
                            <input v-model="create_question_data.question" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="e.g Are you a director of a company?">
                          </div>
                          <div class="col">
                            <label class="form-label fw-bold text-xs"><small>Question Number</small></label>
                            <input v-model="create_question_data.question_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. 1">
                          </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="form-label fw-bold text-xs"><small>Question Type</small></label>
                                <select v-model="create_question_data.question_type" class="form-select text-xs">
                                    <option value="" disabled hidden>Select Type</option>
                                    <option value="File">File</option>
                                    <option value="Date">Date</option>
                                    <option value="Text">Text</option>
                                    <option value="Checkbox">Checkbox</option>
                                    <option value="Selection">Selection</option>
                                    <option value="Number">Number</option>
                                </select>
                            </div>
                            <div class="col">
                                <label class="form-label fw-bold text-xs"><small>Is question mandatory?</small></label>
                                <select v-model="create_question_data.mandatory" class="form-select text-xs">
                                    <option value="" disabled hidden>Select Option</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
    
                        <!-- Inputs for adding option after select  -->
                        <h5 v-show="check_question_type"><strong>Add Options If Applicable</strong></h5>
                        <div class="row">
                            <div v-if="check_question_type" class="col mb-3">
                                <div class="mb-3 ">
                                    <label for="option_1"
                                        class="form-label fw-bold"><small><span>Enter number of options for your question</span></small></label>
                                    <input class="form-control text-xxs" type="number" id="number_of_options" min="1"  v-model="create_question_data.number_of_options" @input="validate_number_of_options"
                                        placeholder="Enter number of options"/>
                                </div>
                            </div>
                            <div  class="col mb-3 mt-4">
                                <div v-for="(option, index) in create_question_data.options" class=" mt-1">
                                    <input 
                                    class="form-control text-xs options"
                                    type="text" 
                                    v-model="create_question_data.options[index]" 
                                    placeholder="Add Option"/>
                                </div>
                            </div>
            
                        </div>
                        <div class="row">
                            <div v-show="check_question_type" class="col mb-3 hide_field">
                                <label for="other_field" class="form-label fw-bold"><small>Do you have an extra field for <span>other</span>
                                                                                    options? *</small></label> 
                                <select class="form-select text-xs" aria-label="other_field" name="other_field" id="other_field">
                                    <option hidden selected value="">Select Option</option>
                                    <option value="False">No</option>
                                    <option value="True">Yes</option>
                                </select>
                            </div>
                            <div class="col"></div>
                        </div>
                        <div class="col text-end">
                            <button type="submit" class="btn bg-green btn-sm text-xxs fw-bold rounded-small mt-3 shadow">Create Question</button>
                        </div>
                    </form>
                </div>     
            </div>
    <div class="card rounded-3 border-0 shadow  mt-4">
        <div class="card-header bg-white border-0">
            Manage Questions
        </div>
        <div class="card-body">
            <table id="question_table" class="table table-clear">
                <thead class="bg-dark text-white">
                    <tr>
                        <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">No.</th>
                        <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Question</th>
                        <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Question Type</th>
                        <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">options (if applicable)</th>
                        <th class="text-uppercase text-xxs font-weight-bolder opacity-7 text-start">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(question, index) in questions" :key="question.id">
                        <td class="text-xxs text-start">{{ index + 1 }}</td>
                        <td class="text-xxs text-start">{{ question.question }}</td>
                        <td class="text-xxs text-start">{{ question.question_type }}</td>
                        <td class="text-xxs text-start"><span v-if="question.options && question.options.length > 0"><ul v-for="item in question.options" :key="item.id">
                            {{ item.option }}
                          </ul></span>
                        </td>
                        <td>
                            <button class="btn btn-dark rounded-small fw-bold btn-sm me-2 text-xxs text-white" @click="edit_question(question.id)">Edit</button>
                            <button v-if="question.is_active" class="btn btn-danger rounded-small fw-bold btn-sm me-2 text-xxs text-white" @click="deactivate_question(question.id)">Deactivate</button>
                            <button v-else class="btn bg-green rounded-small fw-bold btn-sm me-2 text-xs" @click="activate_question(question.id)">Activate</button>
                        </td>
                    </tr>
              </tbody>
            </table>
        </div>
    
    </div>

        <!-- Edit question Modal -->
        <div class="modal fade " id="edit_question_modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="edit_question_modal_label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content bg-white">
            <div class="modal-header border-bottom-0">
              <h5 class="modal-title text-s fw-bold" id="edit_question_modal_label">Edit Question Details</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="col mb-4">
                <label class="form-label fw-bold text-xs"><small>Question</small></label>
                <input v-model="edit_question_data.question" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" required="True" placeholder="e.g Are you a director of a company?">
                </div>
                <div class="col mb-4">
                <label class="form-label fw-bold text-xs"><small>Question Number</small></label>
                <input v-model="edit_question_data.question_number" type="text" class="form-control text-xs rounded-small p-3 shadow-sm" placeholder="eg. 1">
                </div>

                <div class="col mb-4">
                    <label class="form-label fw-bold text-xs"><small>Is Mandatory</small></label>
                    <select v-model="edit_question_data.mandatory" class="form-select text-xs">
                        <option value="" disabled hidden>Select Type</option>
                        <option :value="true" v-if="edit_question_data.mandatory === true">Yes</option>
                        <option :value="false" v-if="edit_question_data.mandatory === false">No</option>
                        <option value="False">No</option>
                        <option value="True">Yes</option>
                    </select>
                </div>
                    
                <div class="col mb-4">
                    <label class="form-label fw-bold text-xs"><small>Question Type</small></label>
                    <select v-model="edit_question_data.question_type" class="form-select text-xs">
                        <option value="" disabled hidden>Select Type</option>
                        <option value="File">File</option>
                        <option value="Date">Date</option>
                        <option value="Text">Text</option>
                        <option value="Checkbox">Checkbox</option>
                        <option value="Selection">Selection</option>
                        <option value="Number">Number</option>
                    </select>
                </div>
                <h6 v-show="check_edit_question_type" class="text-sm">Edit Options If Applicable</h6>
                <div class="row">
                    <div v-show="check_edit_question_type" class="col mb-3">
                        <div class="mb-3 ">
                            <label for="edit_option_1" class="form-label text-xs">
                                <strong><span>Enter number of options for your question</span></strong>
                            </label>
                            <input class="form-control text-xxs" type="number" id="edit_number_of_options" min="1" 
                                   v-model="edit_question_data.number_of_options" @input="validate_number_of_options"
                                   placeholder="Enter number of options" />
                        </div>
                    </div>
                </div>
                <div class="col text-end">
                    <button type="submit" class="btn bg-green btn-sm text-xs fw-bold rounded-pill mt-3" @click="update_question">Save Changes</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>