import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dotenv from 'dotenv';

dotenv.config();

const app = createApp(App);
app.use(router); 
app.use(store);

app.mount('#app');