import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';
export default {
    name: 'CustomerIdVerification',
    components: {
        GetQuestions,
    },
  
    data: () => ({
        id_number: '',
        responses: [],
        client_admin_id: sessionStorage.getItem('client_admin_id'),
    }),
    computed: {
        workflow_id() {
            return this.$store.getters.get_workflow_id;
        }
    },
  
    methods: {
        store_responses(dynamic_fields = []) {
            this.responses = dynamic_fields;
        },
        allow_num(event) {
            let input_value = event.target.value;
            input_value = input_value.replace(/\D/g, '');
            this.id_number = input_value;
        },
  
        // Function to validate the ID number
        validate_id(id_input) {
            var month = parseInt(id_input.substring(2, 4), 10) - 1;
            var day = parseInt(id_input.substring(4, 6), 10);
            var id_year = parseInt(id_input.substring(0, 2), 10);

            if (month < 0 || month > 11) {
            return false;
            }

            if (day < 1 || day > 31) {
            return false;
            }

            if (id_year < new Date().getFullYear() - 100) {
            id_year += 2000;
            } else {
            id_year += 1900;
            }

            var temp_date = new Date(id_year, month, day);
            var id_date = temp_date.getDate();
            var id_month = temp_date.getMonth();

            if (id_month !== month || id_date !== day) {
            return false;
            }

            var check_sum = 0;
            var multiplier = 1;

            for (var i = 0; i < 13; i++) {
            var digit = parseInt(id_input.charAt(i), 10);
            var temp_total = digit * multiplier;

            if (temp_total > 9) {
                temp_total = parseInt(temp_total.toString().charAt(0), 10) + parseInt(temp_total.toString().charAt(1), 10);
            }

            check_sum += temp_total;
            multiplier = (multiplier % 2 === 0) ? 1 : 2;
            }

            if ((check_sum % 10) !== 0) {
            return false;
            }

            return true;
        },
  
        submit_id() {
            if (!this.id_number.trim()) {
                $('.id-message').removeClass('d-none');
                setTimeout(() => {
                    $('.id-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (!this.validate_id(this.id_number)) {
                $('.invalid-message').removeClass('d-none');
                setTimeout(() => {
                    $('.invalid-message').addClass('d-none');
                }, 3000);
                return false;
            }

            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const TOKEN = localStorage.getItem('token');
            const SAVE_ID_VERIFICATION_URL = `${process.env.API_BASE_URL}/verify_api/system_management_api/save_id_verification_api/`;


            const FORM_DATA = {
                client_admin_id: GET_CLIENT_ID,
                id_number: this.id_number,
                responses: this.responses.map(field => ({
                    service_id: field.service_id,
                    question_id: field.question_id,
                    value: field.value
                }))
              };
            $.ajax({
                url: SAVE_ID_VERIFICATION_URL,
                method: 'POST',
                contentType: 'application/json',
                headers: {
                    Authorization: `Token ${TOKEN}` 
                },
                data: JSON.stringify(FORM_DATA),
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    localStorage.setItem('id_number', response.id_number);
                    localStorage.setItem('user_state', 'id_verified');
                    this.$router.push({ name: 'assigned_workflows' });
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Something went wrong!';
        
                    if (error.responseJSON && error.responseJSON.message) {
                        error_message = error.responseJSON.message;
                        $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                    }else {
                        $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);
                    }
                }
            });
        }
    },

    mounted() {
        this.store_responses();
    },
};
