export default {
    data() {
      return {
        services: [
          { id: 1, name: 'Service One' },
          { id: 2, name: 'Service Two' },
        ],
        active_service: null,
      };
    },
    methods: {
      toggle_service_rules(serviceId) {
        this.active_service = this.active_service === serviceId ? null : serviceId;
      },
      route_to_ordering() {
          this.$router.push({ name: 'arrange_workflow' });
      }
    },
  };
  
  
  