import Swal from 'sweetalert2';
import {show_error} from '@/utils/validations.js';
import GetQuestions from '@/components/GetQuestions/GetQuestions.vue';

export default {

    name: 'UserRegistration',
    components: {
        GetQuestions,
    },
    data() {
        return ({
            first_name: '',
            last_name: '',
            email: '',
            confirm_email: '',
            phone_number: '',
            password: '',
            confirm_password: '',
            phone_number_error: false,
            client_admin_id: sessionStorage.getItem('client_admin_id'),
            responses: [],
        });
    },
    
    methods: {
        store_responses(dynamic_fields) {
            this.responses = dynamic_fields;
        },
    
        handle_register() {
            if (this.validate_inputs()) {
                const client_admin_id = sessionStorage.getItem('client_admin_id');
                if (client_admin_id) {
                    this.customer_registration();
                } else {
                    this.user_registration();
                }
            }
        },
        validate_inputs() {
            if (!this.first_name.trim()) {
                $('.first-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.first-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.last_name.trim()) {
                $('.last-name-message').removeClass('d-none');
                setTimeout(() => {
                    $('.last-name-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.email.trim()) {
                $('.email-message').removeClass('d-none');
                setTimeout(() => {
                    $('.email-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.confirm_email.trim()) {
                $('.confirm-email-message').removeClass('d-none');
                setTimeout(() => {
                    $('.confirm-email-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.phone_number.trim()) {
                $('.phone-number-message').removeClass('d-none');
                setTimeout(() => {
                    $('.phone-number-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.password.trim()) {
                $('.password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.password-message').addClass('d-none');
                }, 3000);
                return false;
            }
        
            if (!this.confirm_password.trim()) {
                $('.confirm-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.confirm-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
                setTimeout(() => {
                    $('.match-password-message').addClass('d-none');
                }, 3000);
                return false;
            }

            if (this.email !== this.confirm_email) {
                $('.email-match-message').removeClass('d-none');
                setTimeout(() => {
                    $('.email-match-message').addClass('d-none');
                }, 3000);
                return false;
            } 

            return true; 
        },
        
        password_match() {
            if (this.password !== this.confirm_password) {
                $('.match-password-message').removeClass('d-none');
            } else {
                $('.match-password-message').addClass('d-none');
            }
        },

        email_match() {
            if (this.email !== this.confirm_email) {
                $('.email-match-message').removeClass('d-none');
            } else {
                $('.email-match-message').addClass('d-none');
            }
        },

        validate_phone_number() {

            this.phone_number = this.phone_number.replace(/\D/g, '');
            const PHONE_PATTERN = /^\d{10}$/;

            if (this.phone_number.length === 10 && PHONE_PATTERN.test(this.phone_number)) {
                this.phone_number_error = false;
            } else {
                this.phone_number_error = true;
            }
        },

        validate_name(field_name) {
            let input_value = this[field_name];
    
            input_value = input_value.replace(/[^a-zA-Z\s]/g, '');
    
            this[field_name] = input_value;
        },

        user_registration() {
            if (!this.validate_inputs()) {
                return; 
            }

            const BASE_URL = process.env.API_BASE_URL;
            const REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_api/`;
            const FORM_DATA = {
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                confirm_email: this.confirm_email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password
            };
    
            $.ajax({
            type: "POST",
            url: REGISTRATION_URL,
            data: JSON.stringify(FORM_DATA),
            contentType: 'application/json',
            beforeSend: () => {

                Swal.fire({
                text: 'Loading ...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
                });
            },
            success: (response) => {
                Swal.close();
                localStorage.setItem('user_id', response.user_id);
                localStorage.setItem('token', response.token);
                this.$router.push('/user_otp');
            },
            error: (error) => {
                Swal.close();

                let error_message = 'Registration failed';

                if (error.responseJSON) {
                    if (error.responseJSON.email_exists_error) {
                        error_message = error.responseJSON.email_exists_error;
                        $('.email-exists-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.email-exists-message').text('').addClass('d-none');
                        }, 3000);
                    } else if (error.responseJSON.phone_number_exists_error) {
                        error_message = error.responseJSON.phone_number_exists_error;
                        $('.mobile-exists-message').text(error_message).removeClass('d-none');
                        setTimeout(() => {
                            $('.mobile-exists-message').text('').addClass('d-none');
                        }, 3000);
                    } else {
                        if (error.responseJSON.email) {
                            error_message = error.responseJSON.email;
                            $('.email-error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.email-error-message').text('').addClass('d-none');
                            }, 3000);
                        } else {
                            error_message = 'An error occurred during registration.';
                            $('.error-message').text(error_message).removeClass('d-none');
                            setTimeout(() => {
                                $('.error-message').text('').addClass('d-none');
                            }, 3000);

                        }
                    }
                }
            }
            });
        },

        customer_registration() {
            const is_responses_valid = this.$refs.get_questions.validate_fields();
            if (!this.validate_inputs() || !is_responses_valid) {
                return; 
            }
        
            const GET_CLIENT_ID = sessionStorage.getItem('client_admin_id');
            const GET_ASSET_ID = sessionStorage.getItem('asset_id')
            const GET_WORKFLOW_ID = sessionStorage.getItem('workflow_id');
            const BASE_URL = process.env.API_BASE_URL;
            const CUSTOMER_REGISTRATION_URL = `${BASE_URL}/verify_api/system_management_api/register_customer_api/`;
        
            // Create the basic form data as an object
            const FORM_DATA = {
                client_admin_id: GET_CLIENT_ID,
                asset_id: GET_ASSET_ID,
                workflow_id: GET_WORKFLOW_ID,
                first_name: this.first_name,
                last_name: this.last_name,
                email: this.email,
                phone_number: this.phone_number,
                password: this.password,
                confirm_password: this.confirm_password,
                responses: this.responses.map(field => ({
                    service_id: field.service_id,
                    question_id: field.question_id,
                    value: field.value
                }))
            };
        
            // Check if any response includes a file
            const has_file = this.responses.some(field => field.has_file);
        
            let data_to_send;
            if (has_file) {
                // If there's a file, create a FormData instance
                const form_data = new FormData();
                form_data.append('client_admin_id', GET_CLIENT_ID);
                form_data.append('first_name', this.first_name);
                form_data.append('last_name', this.last_name);
                form_data.append('email', this.email);
                form_data.append('phone_number', this.phone_number);
                form_data.append('password', this.password);
                form_data.append('confirm_password', this.confirm_password);
                form_data.append('workflow_id', this.workflow_id);
        
                // Append responses, handling files if present
                this.responses.forEach((field, index) => {
                    form_data.append(`responses[${index}][service_id]`, field.service_id);
                    form_data.append(`responses[${index}][question_id]`, field.question_id);
                    if (field.has_file) {
                        form_data.append(`responses[${index}][uploaded_file]`, field.value);
                    } else {
                        form_data.append(`responses[${index}][value]`, field.value);
                    }
                });
        
                data_to_send = form_data;
            } else {
                data_to_send = JSON.stringify(FORM_DATA);
            }
        
            // Determine content type based on the presence of files
            const content_type = has_file ? false : 'application/json';
            // Perform the AJAX request
            $.ajax({
                method: "POST",
                url: CUSTOMER_REGISTRATION_URL,
                data: data_to_send,
                processData: !has_file,  // Do not process data if using FormData
                contentType: content_type,
                beforeSend: () => {
                    Swal.fire({
                        text: 'Loading ...',
                        allowEscapeKey: false,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    });
                },
                success: (response) => {
                    Swal.close();
                    localStorage.setItem('user_id', response.user_id);
                    localStorage.setItem('token', response.token);
                    this.$router.push('/user_otp');
                },
                error: (error) => {
                    Swal.close();
                    let error_message = 'Registration failed';
                    if (error.responseJSON && error.responseJSON.error) {
                        error_message = error.responseJSON.error;
                    } else {
                        error_message = 'An error occurred during registration.';
                    }
                    show_error('.error-message', error_message);
                }
            });
        }
    }
};