export default {
    name: 'LandingPage',

    data() {
      return {
        client_admin_id: null,
        company_name: null,
        workflow_name: null,
        workflow_id: null,
        asset_id: null
      };
    },

    mounted() {
      // Fetch the client_id and company_name from the URL query parameters
      const { client_admin_id, company_name } = this.$route.query;
      if (client_admin_id && company_name) {
        this.client_admin_id = client_admin_id;
        this.company_name = company_name; // Store company name for later use
        this.get_client_id_on_session(client_admin_id, company_name);
      }
      const {asset_id, workflow_id, workflow_name} = this.$route.query;
      if (asset_id && workflow_id && workflow_name){
        this.client_admin_id = client_admin_id;
        this.asset_id = asset_id;
        this.workflow_id = workflow_id;
        this.workflow_name = workflow_name;
        this.get_asset_workflow_on_session(client_admin_id, asset_id, workflow_id, workflow_name)
      }
      
    },

    methods: {
        showError(selector, message) {
            $(selector).text(message).removeClass('d-none');
            setTimeout(() => {
                $(selector).addClass('d-none');
            }, 3000);
        },

      get_client_id_on_session(client_admin_id, company_name) {
        const BASE_URL = process.env.API_BASE_URL;
        const GET_CLIENT_ID_URL = `${BASE_URL}/verify_api/${company_name}/${client_admin_id}/`;

        $.ajax({
          method: "GET",
          url: GET_CLIENT_ID_URL,
          contentType: 'application/json',
          success: (response) => {
            if (response.client_admin_id) {
                sessionStorage.setItem('client_admin_id', response.client_admin_id);
                this.$store.commit('client_admin_id', response.client_admin_id);
                this.$router.push({ name: 'landing_page'});
            }
          },
          error: (error) => {
            let error_message = 'Getting client id failed';
            if (error.responseJSON && error.responseJSON.error) {
              error_message = error.responseJSON.error;
            }
            this.showError('.error-message', error_message);
          }
        });
      },

      get_asset_workflow_on_session(client_admin_id, asset_id, workflow_id, workflow_name){
        const BASE_URL = process.env.API_BASE_URL;
        const GET_ASSET_WORFLOW_URL = `${BASE_URL}/verify_api/${workflow_name}/${client_admin_id}/${workflow_id}/${asset_id}/`;

        $.ajax({
          method: "GET",
          url: GET_ASSET_WORFLOW_URL,
          contentType: 'application/json',
          success: (response) => {
            const properties = ['client_admin_id', 'asset_id', 'workflow_id'];
            let has_navigated = false;
    
            properties.forEach((prop) => {
                if (response[prop]) {
                    sessionStorage.setItem(prop, response[prop]);
                    this.$store.commit(prop, response[prop]);
                    this.$router.push({ name: 'landing_page' });
                    has_navigated = true;
                }
            });

            if (has_navigated) {
              this.$router.push({ name: 'landing_page' });
            }
          },
          error: (error) => {
            let error_message = 'Getting client id failed';
            if (error.responseJSON && error.responseJSON.error) {
              error_message = error.responseJSON.error;
            }
            this.showError('.error-message', error_message);
          }
        });
      },

      next_to_register(){
        this.$router.push({ name: 'user_registration' });
      }
    }
  };
  
  
  
  
  