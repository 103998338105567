import UserInfoFields from "@/components/UserInfoFields/UserInfoFields.vue";
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
    components:{UserInfoFields},
    name: 'ProofOfIncomeOnline',
    mixins: [MAIN_CONTROLLER],
    data: () => ({ 
        first_name: '',
        last_name: '',
        email_address:'',
        phone_number:'',
        bank:'',
        bank_account_number:'',
        error: '',
        json_data: {}
    })
}