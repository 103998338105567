<div class="container-fluid">
    <div class="row mt-0">
        <div class="col-md-12">
        <div class="card rounded-3 p-0 mt-4">
            <div class="card-header bg-white border-0">
            {{template_name }}

            </div>
            <div class="card-body">
                <docuseal-builder
                  id="docuseal-builder"
                  :data-token="token"
                  :data-autosave="true"
                  :data-with-title="false"
                  :data-with-upload-button="false"
                  :data-with-sign-yourself-button="false"
                  :data-with-send-button="false"
                  :data-only-defined-fields="true"
                  :data-fields="tags"
                  :data-template-id="id"
                  data-roles="CLIENT_ADMIN"
                >
                </docuseal-builder>
            </div>
        </div>
        </div>
    </div>
</div>