import { validate_id, allow_num  } from "@/utils/validations";
import IdNumberInput from '@/components/IdNumberInput/IdNumberInput.vue'
import { MAIN_CONTROLLER } from "@/utils/main_controller.js";

export default {
  name: 'EligibilityService',
  mixins: [MAIN_CONTROLLER],

  components: {
    IdNumberInput,
  },

  data:() =>({ id_number: '', loading: false,  error: ''}),
  computed: {
    workflow_id() {
      return this.$store.getters.get_workflow_id;
    }
  },
  methods: {
    allow_num(event) {
        this.id_number = allow_num(event);
    },
    handle_id_input(id_number) {
      this.id_number = id_number; // Update the id_number data property with the new value
    },
    submit_id() {
      if (!this.id_number.trim()) {
        $('.id-message').removeClass('d-none');
        setTimeout(() => {
          $('.id-message').addClass('d-none');
        }, 3000);
        return false;
      }

      if (!validate_id(this.id_number)) {
        $('.invalid-message').removeClass('d-none');
        setTimeout(() => {
          $('.invalid-message').addClass('d-none');
        }, 3000);
        return false;
      }
    }
  }
};